import React from 'react'
import {Toaster, ToastBar, Toast} from 'react-hot-toast'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCheck} from '@fortawesome/free-solid-svg-icons'

export const HotToaster: React.FC = () => {
  return (
    <Toaster
      data-testid="hot-toaster"
      containerStyle={{inset: 0}}
      position="top-center"
      toastOptions={{
        className: '',
        duration: 3000,
        style: {
          borderRadius: 3,
          fontSize: 12,
          lineHeight: 0,
          margin: '7px 0 10px',
          padding: '14px 10px 13px',
          display: 'flex',
          alignItems: 'center',
          minWidth: 560,
          maxWidth: 'calc(100% - 40px)',
          textAlign: 'left',
          boxShadow: 'none',
        },
        success: {
          style: {
            color: '#270',
            borderColor: '#DFF2BF',
            backgroundColor: '#DFF2BF',
          },
          icon: (
            <FontAwesomeIcon fontWeight="bold" icon={faCheck} fontSize="20px" />
          ),
        },
        error: {
          style: {
            color: '#D8000C',
            background: '#FFBABA',
            borderColor: '#FFBABA',
          },
        },
      }}
    >
      {(t: Toast): JSX.Element => (
        <ToastBar toast={t}>
          {({icon, message}): JSX.Element => (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
              }}
            >
              {icon}
              <span style={{paddingLeft: 3}} data-testid="toastMessage">
                {' '}
                {message}
              </span>
              {/* <span style={{cursor: 'pointer'}}>
                {t.type !== 'loading' && (
                  <FontAwesomeIcon
                    icon={faTimes}
                    style={{
                      marginLeft: '15px',
                    }}
                    onClick={(): void => toast.dismiss(t.id)}
                  ></FontAwesomeIcon>
                )}
              </span> */}
            </div>
          )}
        </ToastBar>
      )}
    </Toaster>
  )
}
