export const API_URL =
  process.env.REACT_APP_API_URL || 'https://clf-backend.questionpro.com'
export const IS_ON_QA = process.env.REACT_APP_IS_ON_QA
export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL
export const APP_DOMAIN = process.env.REACT_APP_DOMAIN

export const FEATURE_OUTERLOOP =
  process.env.REACT_APP_FEATURE_OUTERLOOP === 'true'
export const FEATURE_ACTION_ALERT =
  process.env.REACT_APP_FEATURE_ACTION_ALERT === 'true'

export const isDevEnv = process.env.NODE_ENV === 'production'
export const LOGOUT_API_URL = process.env.REACT_APP_LOGOUT_API_URL

export const QP_DOMAIN = process.env.REACT_APP_QP_DOMAIN
export const QP_LOGOUT_API_URL = QP_DOMAIN + '/a/logout.do'
export const MY_ACCOUNT_URL =
  QP_DOMAIN + '/a/showUpgradeUser.do?payment=creditCardUpdate'
export const SURVEYS_PRODUCT_URL = QP_DOMAIN + '/a/listSurveys.do'
export const POLLS_PRODUCT_URL = QP_DOMAIN + '/a/listPolls.do'
export const LIVEPOLLS_PRODUCT_URL = ''
export const COMMUNITIES_PRODUCT_URL = QP_DOMAIN + '/a/showPanelManagement.do'
export const WORKFORCE_PRODUCT_URL = QP_DOMAIN + '/a/listMyFlashletSurveys.do'
export const CUSTOMER_EXP_PRODUCT_URL = QP_DOMAIN + '/a/showCXFeedback.do'

const OAUTH_CLIENT_ID = process.env.REACT_APP_OAUTH_CLIENT_ID!
export const OAUTH_URL = `${QP_DOMAIN}/a/oauth/authorize?response_type=code&client_id=${OAUTH_CLIENT_ID}`

export const LS_AUTH_TOKEN = 'livePollToken'
export const QP_AUTH_CODE = 'qpAuthCode'
