import React from 'react'
import {QueryClient, QueryClientProvider} from 'react-query'
import {BrowserRouter} from 'react-router-dom'
import {AuthProvider} from './contexts/AuthProvider'
import {AppProvider} from './contexts/AppProvider'

export const queryClientOptions = {
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
}

interface Props {
  children: React.ReactNode
}

export const AppProviders: React.FC<Props> = ({children}) => {
  const queryClient = new QueryClient(queryClientOptions)

  return (
    <AppProvider>
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>{children}</BrowserRouter>
        </QueryClientProvider>
      </AuthProvider>
    </AppProvider>
  )
}
