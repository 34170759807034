import i18n, {Resource} from 'i18next'
import {initReactI18next} from 'react-i18next'
import langEn from './locales/lang_en.json'
import langDe from './locales/lang_de.json'

interface Resources extends Resource {
  translation: Record<string, string>
}

const resources: Record<string, Resources> = {
  en: {
    translation: langEn,
  },
  de: {
    translation: langDe,
  },
}

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem('lang') || 'en',
  keySeparator: false,
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
})

export default i18n

// These language codes are getting from QuestionPro core
// const languages = {
//   en: 'en',
//   pt_BR: 'pt_BR',
//   ar_SA: 'ar_SA',
//   es_LA: 'es_LA',
//   de_DE: 'de_DE',
//   ja_JPN: 'ja_JPN',
//   fr_FR: 'fr_FR',
//   ru_RU: 'ru_RU',
//   tr_TR: 'tr_TR',
// }
