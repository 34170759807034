import React, {Suspense} from 'react'
import {FullPageSpinner} from './components/spinner/FullPageSpinner'
import {useOptAuth} from './contexts/AuthProvider'

const AuthenticatedApp = React.lazy(() =>
  import('./AuthenticatedApp').then(module => ({
    default: module.AuthenticatedApp,
  })),
)
const UnauthenticatedApp = React.lazy(() =>
  import('./UnauthenticatedApp').then(module => ({
    default: module.UnauthenticatedApp,
  })),
)

const App: React.FC = () => {
  const {user} = useOptAuth()
  if (!user) {
    return (
      <Suspense fallback={<FullPageSpinner />}>
        <UnauthenticatedApp />
      </Suspense>
    )
  }

  return (
    <Suspense fallback={<FullPageSpinner />}>
      <AuthenticatedApp />
    </Suspense>
  )
}

export default App
