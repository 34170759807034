import React from 'react'
import {createRoot} from 'react-dom/client'
import App from './App'
import {AppProviders} from './AppProviders'
import {HotToaster} from './components/common/toast/HotToaster'
import './index.css'
import '@npm-questionpro/wick-ui-icon/dist/wu-icon.css'
import '@npm-questionpro/wick-ui-lib/dist/style.css'
import reportWebVitals from './reportWebVitals'
import './i18n'
import axios from 'axios'
import {redirectTo} from './utils/util'
import {API_URL} from './constants/AppConstants'

axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    console.log(error?.response?.data?.data?.statusCode)
    if (error?.response?.data?.data?.statusCode === 403) {
      redirectTo(`${API_URL}/api/auth/oauth`)
    }

    return Promise.reject(error)
  },
)

const container = document.getElementById('root')!
const root = createRoot(container)

root.render(
  // <React.StrictMode>
  <AppProviders>
    <App />
    <HotToaster />
  </AppProviders>,
  // </React.StrictMode>,
)

reportWebVitals()
