/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'

type Status = 'idle' | 'pending' | 'success' | 'error'

interface ReturnValue<T> {
  value: T | null
  status: Status
  error: Error | null
  execute: (...args: any[]) => void
}

export function useAsync<T>(
  asyncFunction: (...args: any[]) => Promise<T>,
  immediate = true,
): ReturnValue<T> {
  const [status, setStatus] = React.useState<Status>('idle')
  const [value, setValue] = React.useState<T | null>(null)
  const [error, setError] = React.useState<Error | null>(null)
  // The execute function wraps asyncFunction and
  // handles setting state for pending, value, and error.
  // useCallback ensures the below useEffect is not called
  // on every render, but only if asyncFunction changes.
  const execute = React.useCallback(
    (...args: any[]) => {
      setStatus('pending')
      setValue(null)
      setError(null)
      return asyncFunction(...args)
        .then(response => {
          setValue(response)
          setStatus('success')
        })
        .catch(error => {
          setError(error)
          setStatus('error')
        })
    },
    [asyncFunction],
  )
  // Call execute if we want to fire it right away.
  // Otherwise execute can be called later, such as
  // in an onClick handler.
  React.useEffect(() => {
    if (immediate) {
      execute()
    }
  }, [execute, immediate])
  return {execute, status, value, error}
}
