import * as React from 'react'

interface IAppContext {}

const AppContext = React.createContext<IAppContext>({})
AppContext.displayName = 'Closedloop'

interface Props {
  children: React.ReactNode
}

export const AppProvider: React.FC<Props> = ({...props}) => {
  const contextValue = {}
  return <AppContext.Provider value={contextValue} {...props} />
}
